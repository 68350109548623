<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="claimsettlements" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['rmaid']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入RMA ID模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.rmaid"
                                @click="openClaim(slotProps.data,false)" class="p-button-link" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading"
                :saveBeforeMethod="saveBefore" :currentRow="currentRow" :pageJsonConfig="pageJsonConfig"
                :childNewDataConfig="childNewDataConfig" :loadDataAfterMethod="loadDataAfter"
                :closeParentMehod="closeMaximizable" :freshListMethod="freshList" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="上传导入数据" v-model:visible="importDisplay" :style="{width: '30vw'}" :maximizable="true" :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="!startImport" class="field col-12 md:col-12">
                <FileUpload mode="basic" id="importtemplateFileId" style="width:100%" name="importtemplateFile"
                    :url="uploadurl" accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload"
                    @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                    invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <label>{{importfailureinfo}}</label>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport" />
            </div>
        </template>
    </Dialog>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import claimSettlementPageJson from "@/data/claimSettlementConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '729';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                uploadurl: '',
                excelDatas: [],
                startImport: false,
                importDisplay: false,
                importProgress: '',
                importfailureinfo: '',
                importProgressValue: 0,
                importTemplateId: '316423183024197',
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                outOrderShow: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                flowId: '',
                startStateId: '',
                currentState: '流程信息-当前环节:理赔申请',
                currentRow: {
                    bindpage: 'claimsettlement',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'ClaimSettlement',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                pageJsonConfig: {},
                flowhistoryJson: null,
                selectedRow: ref(),
                selectedRow2: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                totalRecords2: 0,
                first2: 0,
                listTitle: '草稿列表',
                selectedKey: {
                    729: true
                },
                childNewDataConfig: {
                    productparts: false,
                    files: true,
                },
                expandedKey: null,
                claimsettlements: [],
                productpartslist: [],
                outstocks: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '理赔操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '导入理赔',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.importclaim();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delclaim();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getclaimSettlement(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.importProgress = '';
                    this.importfailureinfo = '';
                    this.startImport = false;
                    this.importProgressValue = 0;
                    this.excelDatas = [];
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.initFilters1();
                    this.initFilters2();
                    this.loadMenuTree();
                    this.loadFlowInfo();
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    this.loadUserAuth();
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            initFilters2() {
                this.filters2 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'orderid': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'salesdate': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.DATE_BEFORE
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.DATE_AFTER
                        }]
                    },
                    'buyercode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'name': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'productname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'itemcode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'ordertype': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'salesquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'unitprice': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'fulfillmentperunit': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'total': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'outprice': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'productprofit': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'depositamount': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'netamount': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                }
            },
            clearFilter2() {
                this.initFilters2();
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('726', this.currentMenuId, this.$route, this.loadData, this.setlistTitleFun, this
                    .setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '726',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;

                        var createclaimflag = this.$route.query['createclaim'];
                        if (createclaimflag) {
                            this.importclaim();
                        }
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '草稿列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'draftbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'rmaid',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{claimsettlementlist(where:" + JSON.stringify(listwhere) +
                    "){id rmaid rmadate orderid buyercode mpn itemcode reshipmentmpn reshipmentitemcode againgoodnum name treatmentdate aftersalestype aftersalestypename returngoodnum freight claimamount allclaimamount salesdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.claimsettlements = jsonData.data.claimsettlementlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openClaim(v_data) {
                this.currentState = '流程信息-当前环节:' + v_data.currentstatename;
                this.recordsubject = '理赔单' + v_data.rmaid;
                this.currentRow = {
                    isCopy: false,
                    bindpage: 'claimsettlement',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'ClaimSettlement',
                    boxid: v_data.boxid,
                    stateid: this.startStateId,
                    flowid: this.flowId,
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            importclaim() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateClaim')) {
                    MessageTip.warnmsg('请联系管理员开通理赔导入权限');
                    return;
                }
                this.importProgress = '';
                this.importfailureinfo = '';
                this.startImport = false;
                this.importProgressValue = 0;
                this.importDisplay = true;
                this.excelDatas = [];
            },
            delclaim() {
                this.$confirm.require({
                    message: '确认要删除?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        if (!this.selectedRow || this.selectedRow.length == 0) {
                            MessageTip.warnmsg('请选择要删除理赔单');
                            return;
                        }
                        var claimIds = [];
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            claimIds.push({
                                "id": this.selectedRow[k1]["id"]
                            });
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "delete{claimsettlementlist(o:" + JSON.stringify(claimIds) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                this.selectedRow = null;
                                this.loadData(1);
                                MessageTip.successmsg('删除成功');
                            } else {
                                MessageTip.warnmsg('删除失败');
                            }
                        });
                    },
                    reject: () => {}
                });
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                v_data['approvestatus'] = '0';
                v_data['appresult'] = {};
                v_data['shyj'] = {};
                return v_data;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'ClaimSettlement',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId = retObj.FlowId;
                        this.startStateId = retObj.CurrentStateId;
                    }
                });
            },
            closeImportOutStock() {
                this.outOrderShow = false;
                this.selectedRow2 = null;
                this.productpartslist = [];
            },
            importOutStock() {
                if (!this.selectedRow2 || this.selectedRow2.length == 0) {
                    MessageTip.warnmsg('请选择要理赔的出库单');
                    return;
                }
                if (this.selectedRow2.length != 1) {
                    MessageTip.warnmsg('只能选择一个出库单理赔');
                    return;
                }
                this.butLoading = true;
                this.importProductParts();
            },
            importProductParts() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'importProductParts',
                        value: '',
                        operation: ''
                    }, {
                        name: 'parentmpn',
                        value: this.selectedRow2[0]['mpn'],
                        operation: 'equals'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{productpartslist(where:" + JSON.stringify(listwhere) +
                    "){id mpn sku name productid matching}}"
                ).then(res => {
                    this.loading = false;
                    this.butLoading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.productpartslist = jsonData.data.productpartslist;
                        this.recordsubject = '新建理赔';
                        this.currentRow = {
                            isCopy: false,
                            bindpage: 'claimsettlement',
                            id: '-1',
                            workflowid: '',
                            flowguid: 'ClaimSettlement',
                            boxid: '',
                            stateid: this.startStateId,
                            flowid: this.flowId,
                        };
                        this.outOrderShow = false;
                        this.displayMaximizable = true;
                    }
                });
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                if (this.currentRow && this.currentRow['id'] == '-1') {
                    for (var k = 0; k < v_mainelements.length; k++) {
                        if (v_mainelements[k]['name'] == 'orderid') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['orderid'];
                        }
                        if (v_mainelements[k]['name'] == 'salesdate') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['salesdate'];
                        }
                        if (v_mainelements[k]['name'] == 'buyercode') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['buyercode'];
                        }
                        if (v_mainelements[k]['name'] == 'name') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['name'];
                        }
                        if (v_mainelements[k]['name'] == 'mpn') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['mpn'];
                        }
                        if (v_mainelements[k]['name'] == 'itemcode') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['itemcode'];
                        }
                        if (v_mainelements[k]['name'] == 'productname') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['productname'];
                        }
                        if (v_mainelements[k]['name'] == 'salesquantity') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['salesquantity'];
                        }
                        if (v_mainelements[k]['name'] == 'averageprice') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['outprice'];
                        }
                        if (v_mainelements[k]['name'] == 'total') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['total'];
                        }
                        if (v_mainelements[k]['name'] == 'netamount') {
                            v_mainelements[k]['val'] = this.selectedRow2[0]['netamount'];
                        }
                    }
                    for (var k2 = 0; k2 < v_childelements.length; k2++) {
                        if (v_childelements[k2]['name'] == 'productparts') {
                            v_childelements[k2]['rows'] = [];
                            for (var k3 = 0; k3 < this.productpartslist.length; k3++) {
                                var productPartsObj = {
                                    id: '-' + this.productpartslist[k3]['id'],
                                    mpn: this.productpartslist[k3]['mpn'],
                                    sku: this.productpartslist[k3]['sku'],
                                    name: this.productpartslist[k3]['name'],
                                    matching: this.productpartslist[k3]['matching'],
                                    parentid: ''
                                };
                                v_childelements[k2]['rows'].push(productPartsObj);
                            }
                            if (v_childelements[k2]['rows'].length == 0) {
                                v_childelements.splice(k2, 1);
                            }
                        }
                        if (v_childelements[k2]['name'] == 'files') {
                            v_childelements[k2]['rows'] = [];
                        }
                    }
                } else {
                    for (var k4 = 0; k4 < v_childelements.length; k4++) {
                        if (v_childelements[k4]['name'] == 'productparts') {
                            if (v_childelements[k4]['rows'].length == 0) {
                                v_childelements.splice(k4, 1);
                                break;
                            }
                        }
                    }
                }
            },
            onImportUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/ExcelTemplateImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            templateid: this.importTemplateId,
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            this.excelDatas = JSON.parse(unescape(Base64.decode(res.data)));
                            this.startImport = true;
                            this.uploadExcelData();
                        }
                    });
                } else {
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeImportUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '1');
                event.formData.append('attachttype', '1');
            },
            closeImport() {
                this.loadData(1);
                this.importProgress = '';
                this.importfailureinfo = '';
                this.startImport = false;
                this.importProgressValue = 0;
                this.excelDatas = [];
                this.importDisplay = false;
            },
            uploadExcelData() {
                if (this.excelDatas.length > 0) {
                    this.importProgress = '共' + this.excelDatas[0]['arrayData'].length + '条数据,已导入0条,还剩余' + this
                        .excelDatas[0]['arrayData'].length + '条';
                    setTimeout(() => {
                        this.continueUploadExcelData(this.excelDatas[0]['arrayData'], 0, 0, 0);
                    }, 10);
                }
            },
            continueUploadExcelData(alldatas, importIndex, failureIndex, currentIndex) {
                if (alldatas.length > currentIndex) {
                    this.importProgress = '共' + alldatas.length + '条数据,正导入第' + (currentIndex + 1) +
                        '条数据,已成功导入' + importIndex + '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex -
                            failureIndex) + '条';
                    var orderObj = alldatas[currentIndex];
                    this.createClaimSettlement(orderObj, alldatas, importIndex, failureIndex, currentIndex);
                } else {
                    this.importProgress = '共' + alldatas.length + '条数据,已成功导入' + importIndex +
                        '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex - failureIndex) + '条';
                }
            },
            createClaimSettlement(order, alldatas, importIndex, failureIndex, currentIndex) {
                console.log(order);
                if (order['againgoodnum'] == '') {
                    order['againgoodnum'] = 0;
                }
                if (order['claimamount'] == '') {
                    order['claimamount'] = 0;
                }
                var againgoodnum = Number(order['againgoodnum']);
                var claimamount = Number(order['claimamount']);
                var claimsettlementObj = {
                    id: '-1',
                    aftersalestype: '-1',
                    aftersalestypename: '',
                    approvestatus: '0',
                    averageprice: 0,
                    batchno: '',
                    buyercode: '',
                    claimamount: claimamount,
                    claimreason: order['claimreason'],
                    freight: 0,
                    itemcode: order['itemcode'],
                    mpn: order['mpn'],
                    name: order['name'],
                    netamount: 0,
                    orderid: order['orderid'],
                    productname: '',
                    returngoodnum: 0,
                    againgoodnum: againgoodnum,
                    salesdate: order['salesdate'],
                    salesquantity: 0,
                    total: 0,
                    treatmentdate: '',
                    reshipmentmpn: order['reshipmentmpn'],
                    reshipmentitemcode: order['reshipmentitemcode'],
                    rmaid: order['rmaid'],
                    rmadate: order['rmadate'],
                    operatorid: sessionStorage.getItem("userid"),
                    operator: sessionStorage.getItem("username"),
                    storagewarehouse: -1,
                    storeid: sessionStorage.getItem("storeid"),
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    'import{claimsettlement(flowguid:"claimsettlement",workflowid:"-1",boxid:"-1",o:' + JSON
                    .stringify(claimsettlementObj) +
                    '){id errmsg}}'
                ).then(res => {
                    if (res.errcode == "0") {
                        importIndex = importIndex + 1;
                    } else {
                        MessageTip.warnmsg('导入第' + (currentIndex + 1) + '条数据失败');
                        failureIndex = failureIndex + 1;
                        if (this.importfailureinfo == '') {
                            this.importfailureinfo = '导入第' + (currentIndex + 1) +
                                '条数据失败(' + res.err + ')';
                        } else {
                            this.importfailureinfo = this.importfailureinfo + ';导入第' + (currentIndex +
                                    1) +
                                '条数据失败(' + res.err + ')';
                        }
                    }
                    currentIndex = currentIndex + 1;
                    this.importProgressValue = (((importIndex + failureIndex) / alldatas.length) * 100)
                        .toFixed(0);
                    console.log('importProgressValue:' + this.importProgressValue);
                    console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                    if (this.startImport) {
                        setTimeout(() => {
                            this.continueUploadExcelData(alldatas, importIndex, failureIndex,
                                currentIndex);
                        }, 10);
                    }
                });
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            'AppFooter': AppFooter,
        }
    }
</script>